/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/* IOS StyleFix */
ion-app {
  margin-top: env(safe-area-inset-top);
  margin-bottom: env(safe-area-inset-bottom);

  --safe-area-inset-top: env(safe-area-inset-top);
  --safe-area-inset-bottom: env(safe-area-inset-bottom);
  max-height: calc(100% + (var(--safe-area-inset-top) + var(--safe-area-inset-bottom)));
}

.ril__toolbar {
  margin-top: 20px;
  background: black;
}

/* -------- Global -------- */
a {
  color: var(--ion-color-protored)
}

a:hover {
  color: var(--ion-color-protored-shade)
}


/* -------- Landing -------- */
.landingPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  background: white;
  text-align: center;
  margin-left: auto;
  margin-right: auto
}

.LandingPageButton {
  max-width: 400;
}

.impressumHeading {
  font-size: 15;
  font-weight: 600;
}

/* -------- Content Select -------- */

.quickSelectWrapper {
  flex-direction: column;
  max-height: 24vh;
  width: 100%;
  border-top: 1px solid grey;
}

.quickSelectWrapperInner {
  border-top: 1px solid grey;
  padding: 0 10px 50px 10px;
  max-height: 20vh;
  overflow-y: scroll;
}

/* -------- Map -------- */

@import '~leaflet/dist/leaflet.css';

.helpFileHeading {
  color: var(--ion-color-protodark);
  font-size: medium;
  font-weight: 500;
  text-align: center;
  margin-bottom: -4px;
}

.helpFileParagraph {
  font-size: smaller;
  text-align: justify;
}

.helpFileImage {
  height: 65%;
  width: 65%;
  padding: 4px;
  align-self: center;
  border: 1px solid gray;
  margin-bottom: 8px;
  margin-top: 4px;
}

.mdblob {
  text-align: justify;
  padding: "0 4px";
}

.mdblob img {
  width: 100%;
}

/* Web only: Control is added twice, rm  duplicate */
div.leaflet-bottom.leaflet-right div:nth-child(2) {
  display: none;
}

/* Style Locate Button */
a.leaflet-bar-part.leaflet-bar-part-single {
  position: absolute;
  bottom: 6px;
  right: 6px;
  background-color: var(--ion-color-protored);
  height: 60px;
  width: 60px;
  border: 1px solid #0000007d;
  border-radius: 30px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

a.leaflet-bar-part.leaflet-bar-part-single:before {
  content: '';
  background: url('../../public/assets/icon/locate.svg');
  background-size: cover;
  position: absolute;
  width: 30px;
  height: 30px;
}

/* Remove Routing Control button from map: */
div.leaflet-routing-container {
  visibility: hidden;
}

.leafletBasemap {
  filter: grayscale(100%);
}

.mapWrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  order: 2;
}

#stationButton {
  color: var(--ion-color-protored)
}

#stationButtonIgnore {
  color: var(--ion-color-protodark)
}


ion-toast::part(button) {
  color: var(--ion-color-protored);
  margin-right: 6px;
}

.actionSheetStationNear {
  --background: #e5e5e5;
  --backdrop-opacity: 0.8;
}

.toastSkipStation {
  background-color: rgba(0, 0, 0, 0.6)
}


/*
-------- Station Component -------- 
*/

.noEntry {
  text-align: left;
}

.withEntry {
  text-align: left;
  padding-right: 4px;
  color: var(--ion-color-protored);
}

audio::-webkit-media-controls-panel {
  background-color: white;
}


/* -------- .md Buttons -------- */
div.mdButtonWrapper {
  text-align: center;
  margin-top: -6px;
}

.cardWrapper {
  display: "flex";
  flex-direction: "column";
  justify-content: "center";
  background-color: "white";
  padding: "0 4px";
}

.projectButton {
  height: 40px;
  line-height: 40px;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  color: var(--ion-color-protored);
  background-color: #ffffff;
  border: 1px solid var(--ion-color-protored);
  border-radius: 64px;
  outline: none;
  min-width: 190px;
}

.articleButton {
  height: 40px;
  line-height: 40px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  color: #ffffff;
  background-color: var(--ion-color-protored);
  border-radius: 64px;
  outline: none;
  min-width: 190px;
}

/* -------- Gallery -------- */
.gallery {
  position: relative;
}

.gallery ion-icon {
  position: absolute;
}

#gallery-overlay {
  bottom: 4%;
  right: 4%;
  font-weight: bold;
  text-align: right;
  height: 30px;
  width: 30px;
  padding: 12px;
  border-radius: 100%;
  background-color: var(--ion-color-protored);
  border: 1px solid #0000002f;
  color: white;
}

#audio-overlay {
  bottom: 4%;
  left: 4%;
  font-weight: bold;
  text-align: right;
  height: 20px;
  width: 20px;
  padding: 10px;
  margin-bottom: 2px;
  border-radius: 100%;
  background-color: white;
  color: var(--ion-color-protored);
  border: 1px solid #0000002f;
}

#audio-stop-footer {
  font-weight: bold;
  text-align: right;
  height: 14px;
  width: 14px;
  padding: 10px;
  margin-bottom: 2px;
  margin-left: 14px;
  border-radius: 100%;
  background-color: white;
  color: var(--ion-color-protored);
  border: 1px solid var(--ion-color-protored);
}



hr {
  display: block;
  position: relative;
  padding: 0;
  margin: 16px auto 10px auto;
  height: 0;
  width: 100%;
  max-height: 0;
  font-size: 1px;
  line-height: 0;
  clear: both;
  border: none;
  border-top: 1px solid #aaaaaa;
}

/* -------- Quiz -------- */
ion-popover {
  --backdrop-opacity: 0.7;
  --box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.6);
  --width: 300px;
}

#popoverCorrectAnswer {
  --background: #2fdf75;
  --color: white;
}

#popoverIncorrectAnswer {
  --background: #df322f;
  --color: white;
}

#background-content::part(background) {
  background: inherit !important;
}

ion-popover::part(backdrop) {
  background-color: rgb(32, 32, 32);
}

ion-popover::part(content) {
  text-align: center;
  top: unset !important;
  left: 0 !important;
  width: 100vw;
  border-radius: 0;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: inherit !important;
}

.quizItem {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;
  width: 100%
}

.quizImg {
  width: 100%;
  padding: 10px 20px;
  margin-bottom: 12;
}

.quizWrapper {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.quizAnswerButtonWrapper {
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
}

/* -------- Custom Colors  -------- */
/** Generated with https: //ionicframework.com/docs/theming/color-generator **/

:root {

  --ion-background-color: #ffffff;
  --ion-background-color-rgb: rgb(255, 255, 255);
  --ion-tab-bar-background: #ffffff;
  --ion-toolbar-background: #ffffff;
  --ion-item-background: #ffffff;

  --ion-color-protored: #A31111;
  --ion-color-protored-rgb: 163, 17, 17;
  --ion-color-protored-contrast: #ffffff;
  --ion-color-protored-contrast-rgb: 255, 255, 255;
  --ion-color-protored-shade: #8f0f0f;
  --ion-color-protored-tint: #ac2929;

  --ion-color-protodark: #454545;
  --ion-color-protodark-rgb: 69, 69, 69;
  --ion-color-protodark-contrast: #ffffff;
  --ion-color-protodark-contrast-rgb: 255, 255, 255;
  --ion-color-protodark-shade: #3d3d3d;
  --ion-color-protodark-tint: #585858;

  --ion-color-darkfont: #393939;
  --ion-color-darkfont-rgb: 57, 57, 57;
  --ion-color-darkfont-contrast: #ffffff;
  --ion-color-darkfont-contrast-rgb: 255, 255, 255;
  --ion-color-darkfont-shade: #323232;
  --ion-color-darkfont-tint: #4d4d4d;

  --ion-color-intermediatefont: #9B9B9B;
  --ion-color-intermediatefont-rgb: 155, 155, 155;
  --ion-color-intermediatefont-contrast: #000000;
  --ion-color-intermediatefont-contrast-rgb: 0, 0, 0;
  --ion-color-intermediatefont-shade: #888888;
  --ion-color-intermediatefont-tint: #a5a5a5;

  --ion-color-lightfont: #EAEAEA;
  --ion-color-lightfont-rgb: 234, 234, 234;
  --ion-color-lightfont-contrast: #000000;
  --ion-color-lightfont-contrast-rgb: 0, 0, 0;
  --ion-color-lightfont-shade: #cecece;
  --ion-color-lightfont-tint: #ececec;


  --ion-color-protored: #A31111;
  --ion-color-protored-rgb: 163, 17, 17;
  --ion-color-protored-contrast: #ffffff;
  --ion-color-protored-contrast-rgb: 255, 255, 255;
  --ion-color-protored-shade: #8f0f0f;
  --ion-color-protored-tint: #ac2929;

  --ion-color-protodark: #454545;
  --ion-color-protodark-rgb: 69, 69, 69;
  --ion-color-protodark-contrast: #ffffff;
  --ion-color-protodark-contrast-rgb: 255, 255, 255;
  --ion-color-protodark-shade: #3d3d3d;
  --ion-color-protodark-tint: #585858;

  --ion-color-darkfont: #393939;
  --ion-color-darkfont-rgb: 57, 57, 57;
  --ion-color-darkfont-contrast: #ffffff;
  --ion-color-darkfont-contrast-rgb: 255, 255, 255;
  --ion-color-darkfont-shade: #323232;
  --ion-color-darkfont-tint: #4d4d4d;

  --ion-color-intermediatefont: #9B9B9B;
  --ion-color-intermediatefont-rgb: 155, 155, 155;
  --ion-color-intermediatefont-contrast: #000000;
  --ion-color-intermediatefont-contrast-rgb: 0, 0, 0;
  --ion-color-intermediatefont-shade: #888888;
  --ion-color-intermediatefont-tint: #a5a5a5;

  --ion-color-lightfont: #EAEAEA;
  --ion-color-lightfont-rgb: 234, 234, 234;
  --ion-color-lightfont-contrast: #000000;
  --ion-color-lightfont-contrast-rgb: 0, 0, 0;
  --ion-color-lightfont-shade: #cecece;
  --ion-color-lightfont-tint: #ececec;


  /** IONIC COLORS **/
  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

/** CUSTOM COLORS - Class implementation**/

.ion-color-protored {
  --ion-color-base: var(--ion-color-protored);
  --ion-color-base-rgb: var(--ion-color-protored-rgb);
  --ion-color-contrast: var(--ion-color-protored-contrast);
  --ion-color-contrast-rgb: var(--ion-color-protored-contrast-rgb);
  --ion-color-shade: var(--ion-color-protored-shade);
  --ion-color-tint: var(--ion-color-protored-tint);
}

.ion-color-protodark {
  --ion-color-base: var(--ion-color-protodark);
  --ion-color-base-rgb: var(--ion-color-protodark-rgb);
  --ion-color-contrast: var(--ion-color-protodark-contrast);
  --ion-color-contrast-rgb: var(--ion-color-protodark-contrast-rgb);
  --ion-color-shade: var(--ion-color-protodark-shade);
  --ion-color-tint: var(--ion-color-protodark-tint);
}

.ion-color-lightfont {
  --ion-color-base: var(--ion-color-lightfont);
  --ion-color-base-rgb: var(--ion-color-lightfont-rgb);
  --ion-color-contrast: var(--ion-color-lightfont-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lightfont-contrast-rgb);
  --ion-color-shade: var(--ion-color-lightfont-shade);
  --ion-color-tint: var(--ion-color-lightfont-tint);
}

.ion-color-intermediatefont {
  --ion-color-base: var(--ion-color-intermediatefont);
  --ion-color-base-rgb: var(--ion-color-intermediatefont-rgb);
  --ion-color-contrast: var(--ion-color-intermediatefont-contrast);
  --ion-color-contrast-rgb: var(--ion-color-intermediatefont-contrast-rgb);
  --ion-color-shade: var(--ion-color-intermediatefont-shade);
  --ion-color-tint: var(--ion-color-intermediatefont-tint);
}

.ion-color-darkfont {
  --ion-color-base: var(--ion-color-darkfont);
  --ion-color-base-rgb: var(--ion-color-darkfont-rgb);
  --ion-color-contrast: var(--ion-color-darkfont-contrast);
  --ion-color-contrast-rgb: var(--ion-color-darkfont-contrast-rgb);
  --ion-color-shade: var(--ion-color-darkfont-shade);
  --ion-color-tint: var(--ion-color-darkfont-tint);
}